var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "favorite-list",
      class: { "favorite-list--none": !_vm.hasContents },
    },
    [
      _vm.hasContents
        ? _c("div", { staticClass: "favorite-list__inner" }, [
            _c("h1", { staticClass: "favorite-list__title" }, [
              _vm._v(_vm._s(_vm.WORDS.FAVORITE_LIST.TITLE)),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.WORDS.FAVORITE_LIST.DESCRIPTION))]),
          ])
        : _vm._e(),
      _vm.hasContents
        ? _vm._l(_vm.contents, function (contentItems, i) {
            return _c("LogCards", {
              key: i,
              attrs: { contents: contentItems, "log-card-type": "favorite" },
            })
          })
        : !_vm.$_loading_state
        ? [
            _c("Empty", {
              attrs: {
                "icon-name": "bell",
                text: _vm.WORDS.FAVORITE_LIST.EMPTY,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }