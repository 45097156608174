<template>
  <div class="favorite-list" :class="{ 'favorite-list--none': !hasContents }">
    <div v-if="hasContents" class="favorite-list__inner">
      <h1 class="favorite-list__title">{{ WORDS.FAVORITE_LIST.TITLE }}</h1>
      <p>{{ WORDS.FAVORITE_LIST.DESCRIPTION }}</p>
    </div>

    <template v-if="hasContents">
      <LogCards
        v-for="(contentItems, i) in contents"
        :key="i"
        :contents="contentItems"
        log-card-type="favorite" />
    </template>

    <template v-else-if="!$_loading_state">
      <Empty icon-name="bell" :text="WORDS.FAVORITE_LIST.EMPTY" />
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { groupBy, orderBy } from 'lodash';
import { SystemDialogMessage } from '@/constants/enums';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { WORDS } from '@/constants/words';

export default defineComponent({
  components: {
    LogCards: () => import('@/components/mypage/LogCards.vue')
  },

  setup: (props, context) => {
    const logs = ref([]);
    const hasContents = computed(() => contents.value.length > 0);

    /**
     * UI展開用に整形したマイル履歴一覧情報
     */
    const contents = computed(() => {
      // 履歴データを日付でグループ化
      const group = groupBy(logs.value, (log) =>
        moment(log.publish_start_at).format('YYYYMMDD')
      );

      // 登録日時 降順にソート
      return orderBy(Object.entries(group), ['0'], 'desc').map((v) => ({
        date: context.root.$utilities.localizeDateWeek(v[0]),
        items: orderBy(
          v[1]
            .map((log) => ({
              ...log,
              src: log.image_url
            }))
            .filter((v) => v),
          ['publish_start_at']
        )
      }));
    });

    const fetchList = async () => {
      try {
        context.root.$_loading_start();

        const data = await context.root
          .$repositories('baristaFavorite')
          .getList();
        logs.value.push(...data.data);
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    fetchList();

    return {
      WORDS,
      hasContents,
      contents
    };
  }
});
</script>

<style lang="scss" scoped>
.favorite-list {
  padding-bottom: 2.4rem;

  &__inner {
    padding: 1.6rem;
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    margin-bottom: 0.8rem;
    font-size: 2rem;
    line-height: 1.25;
  }
}
</style>
